import React from "react";

const HeroSection = ({
  textColor,
  title,
  description,
  descriptionClass = "lg:max-w-2xl sm:text-xl text-base sm:font-medium lg:px-0 sm:px-7 px-4",
  headingClass = "text-3.25xl sm:text-4.5xl sm:mb-8 mb-6 font-semibold ",
  mainClass = "px-5 mx-auto lg:px-4 md:px-3",
}) => {
  return (
    <div className={`max-w-4xl mx-auto ${mainClass}`}>
      <div className="pb-12 sm:pb-20 ">
        <div className="relative flex flex-col items-center text-center">
          <span
            className={`pricing_heading font-semibold ${headingClass}`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div
            style={{ color: `${textColor}` }}
            className={`text-gray-600 tracking-tight_1 sm:tracking-tight ${descriptionClass}`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
