import React from "react";

export const UpcomingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g opacity="0.4" filter="url(#filter0_f_66_7287)">
      <circle cx="12" cy="12" r="8" fill="#F5222D" />
    </g>
    <circle cx="12" cy="12" r="8" fill="url(#paint0_radial_66_7287)" />
    <circle cx="12.0001" cy="12" r="2.4" fill="#F5222D" />
    <defs>
      <filter
        id="filter0_f_66_7287"
        x="0"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_66_7287"
        />
      </filter>
      <radialGradient
        id="paint0_radial_66_7287"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12 12) rotate(153.435) scale(5.36656)"
      >
        <stop offset="0.640625" stop-color="#F5222D" />
        <stop offset="1" stop-color="#F5222D" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const FeaturedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g opacity="0.8">
      <path
        d="M13.5 11.5C13.5 9.75625 12.4781 8.25 11 7.54844V4.95781C11 4.72187 10.9172 4.49375 10.7641 4.31406L8.38281 1.49063C8.28281 1.37188 8.14062 1.3125 8 1.3125C7.85938 1.3125 7.71719 1.37188 7.61719 1.49063L5.23594 4.31406C5.08385 4.49412 5.00029 4.72212 5 4.95781V7.54844C3.52188 8.25 2.5 9.75625 2.5 11.5H4.94531C4.90937 11.6125 4.89062 11.7344 4.89062 11.8719C4.89062 12.2172 5.00937 12.5547 5.225 12.8219C5.401 13.0404 5.6341 13.2059 5.89844 13.3C6.25938 14.1438 7.07969 14.6875 8 14.6875C8.45469 14.6875 8.89531 14.5531 9.27188 14.3C9.64063 14.0531 9.92656 13.7078 10.1 13.3C10.3642 13.2065 10.5973 13.0415 10.7734 12.8234C10.9894 12.5538 11.1073 12.2189 11.1078 11.8734C11.1078 11.7422 11.0906 11.6172 11.0594 11.5016H13.5V11.5ZM11.9109 9.70938C12.0578 9.9375 12.1766 10.1828 12.2625 10.4375H10.9375V8.72969C11.3273 8.98408 11.659 9.31793 11.9109 9.70938ZM6.0625 7.54844V4.98125L8 2.68438L9.9375 4.98125V10.4375H6.0625V7.54844ZM3.7375 10.4375C3.82344 10.1828 3.94219 9.9375 4.08906 9.70938C4.34375 9.31563 4.675 8.98281 5.0625 8.72969V10.4375H3.7375ZM9.81406 12.2531C9.73281 12.3 9.63906 12.3187 9.54688 12.3062L9.24219 12.2688L9.19844 12.5719C9.11406 13.1641 8.59844 13.6109 8 13.6109C7.40156 13.6109 6.88594 13.1641 6.80156 12.5719L6.75781 12.2672L6.45312 12.3062C6.3605 12.3173 6.26678 12.2981 6.18594 12.2516C6.05 12.1734 5.96562 12.0281 5.96562 11.8703C5.96562 11.7047 6.05781 11.5672 6.19375 11.4984H9.80781C9.94531 11.5688 10.0359 11.7063 10.0359 11.8703C10.0344 12.0297 9.95 12.1766 9.81406 12.2531ZM7.25 6.25C7.25 6.44891 7.32902 6.63968 7.46967 6.78033C7.61032 6.92098 7.80109 7 8 7C8.19891 7 8.38968 6.92098 8.53033 6.78033C8.67098 6.63968 8.75 6.44891 8.75 6.25C8.75 6.05109 8.67098 5.86032 8.53033 5.71967C8.38968 5.57902 8.19891 5.5 8 5.5C7.80109 5.5 7.61032 5.57902 7.46967 5.71967C7.32902 5.86032 7.25 6.05109 7.25 6.25Z"
        fill="white"
      />
    </g>
  </svg>
);

export const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    className="w-5 h-5 sm:w-auto sm:h-auto"
  >
    <g opacity="0.7">
      <path
        d="M25.375 6.125H21V4.59375C21 4.47344 20.9016 4.375 20.7812 4.375H19.25C19.1297 4.375 19.0312 4.47344 19.0312 4.59375V6.125H14.9844V4.59375C14.9844 4.47344 14.8859 4.375 14.7656 4.375H13.2344C13.1141 4.375 13.0156 4.47344 13.0156 4.59375V6.125H8.96875V4.59375C8.96875 4.47344 8.87031 4.375 8.75 4.375H7.21875C7.09844 4.375 7 4.47344 7 4.59375V6.125H2.625C2.14102 6.125 1.75 6.51602 1.75 7V22.75C1.75 23.234 2.14102 23.625 2.625 23.625H25.375C25.859 23.625 26.25 23.234 26.25 22.75V7C26.25 6.51602 25.859 6.125 25.375 6.125ZM24.2812 21.6562H3.71875V8.09375H7V9.625C7 9.74531 7.09844 9.84375 7.21875 9.84375H8.75C8.87031 9.84375 8.96875 9.74531 8.96875 9.625V8.09375H13.0156V9.625C13.0156 9.74531 13.1141 9.84375 13.2344 9.84375H14.7656C14.8859 9.84375 14.9844 9.74531 14.9844 9.625V8.09375H19.0312V9.625C19.0312 9.74531 19.1297 9.84375 19.25 9.84375H20.7812C20.9016 9.84375 21 9.74531 21 9.625V8.09375H24.2812V21.6562ZM11.375 13.5625H6.34375C6.22344 13.5625 6.125 13.6609 6.125 13.7812V15.0938C6.125 15.2141 6.22344 15.3125 6.34375 15.3125H11.375C11.4953 15.3125 11.5938 15.2141 11.5938 15.0938V13.7812C11.5938 13.6609 11.4953 13.5625 11.375 13.5625ZM11.375 17.2812H6.34375C6.22344 17.2812 6.125 17.3797 6.125 17.5V18.8125C6.125 18.9328 6.22344 19.0312 6.34375 19.0312H11.375C11.4953 19.0312 11.5938 18.9328 11.5938 18.8125V17.5C11.5938 17.3797 11.4953 17.2812 11.375 17.2812ZM19.8023 12.4305L16.9695 16.3598L15.5258 14.3609C15.4437 14.2461 15.3125 14.1805 15.173 14.1805H13.6719C13.4941 14.1805 13.3902 14.3828 13.4941 14.5277L16.6141 18.8535C16.6543 18.9094 16.7073 18.9549 16.7687 18.9863C16.83 19.0177 16.8979 19.0341 16.9668 19.0341C17.0357 19.0341 17.1036 19.0177 17.1649 18.9863C17.2263 18.9549 17.2793 18.9094 17.3195 18.8535L21.8313 12.6C21.9352 12.4551 21.8313 12.2527 21.6535 12.2527H20.1523C20.0156 12.25 19.8844 12.3184 19.8023 12.4305Z"
        fill="white"
      />
    </g>
  </svg>
);

export const TimeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className="w-5 h-5 sm:w-auto sm:h-auto"
  >
    <path
      d="M12 1.50012C6.20156 1.50012 1.5 6.20168 1.5 12.0001C1.5 17.7986 6.20156 22.5001 12 22.5001C17.7984 22.5001 22.5 17.7986 22.5 12.0001C22.5 6.20168 17.7984 1.50012 12 1.50012ZM12 20.7189C7.18594 20.7189 3.28125 16.8142 3.28125 12.0001C3.28125 7.18606 7.18594 3.28137 12 3.28137C16.8141 3.28137 20.7188 7.18606 20.7188 12.0001C20.7188 16.8142 16.8141 20.7189 12 20.7189Z"
      fill="white"
    />
    <path
      d="M16.0945 14.9673L12.7523 12.5509V6.75012C12.7523 6.647 12.668 6.56262 12.5648 6.56262H11.4375C11.3344 6.56262 11.25 6.647 11.25 6.75012V13.2048C11.25 13.2657 11.2781 13.322 11.3273 13.3571L15.2039 16.1837C15.2883 16.2446 15.4055 16.2259 15.4664 16.1438L16.1367 15.2298C16.1976 15.1431 16.1789 15.0259 16.0945 14.9673Z"
      fill="white"
    />
  </svg>
);

export const HeroCardVector = () => (
  <svg
    width="1762"
    height="1449"
    viewBox="0 0 1762 1449"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M1070.57 1095.07C1335.25 1093.98 1464.88 927.092 1360.19 722.376C1255.51 517.661 956.11 352.547 691.429 353.641C426.749 354.736 297.125 521.624 401.809 726.339C506.493 931.055 805.89 1096.17 1070.57 1095.07Z"
        stroke="url(#paint0_radial_82_782)"
        stroke-width="0.45"
      />
    </g>
    <g opacity="0.6">
      <path
        d="M608.241 1017.74C449.081 1066.78 339.952 990.885 364.37 848.357C388.789 705.828 537.538 550.476 696.697 501.436C855.857 452.397 964.986 528.293 940.568 670.822C916.149 813.351 767.4 968.703 608.241 1017.74Z"
        stroke="url(#paint1_radial_82_782)"
        stroke-width="0.72"
      />
      <ellipse
        cx="262.274"
        cy="301.824"
        rx="262.274"
        ry="301.824"
        transform="matrix(-0.334103 0.942536 -0.991831 0.127561 1040.32 468.302)"
        stroke="url(#paint2_radial_82_782)"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-dasharray="0.18 2.43"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_82_782"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(881 724.358) rotate(-0.236908) scale(479.42 371.698)"
      >
        <stop stop-color="#C5BBFF" />
        <stop offset="0.479167" stop-color="#C5BBFF" />
        <stop offset="1" stop-color="#C5BBFF" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_82_782"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(652.469 759.589) rotate(162.875) scale(301.824 234.006)"
      >
        <stop stop-color="#C5BBFF" />
        <stop offset="0.479167" stop-color="#C5BBFF" />
        <stop offset="1" stop-color="#C5BBFF" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_82_782"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(262.274 301.824) rotate(90) scale(301.824 262.274)"
      >
        <stop stop-color="#C5BBFF" />
        <stop offset="0.479167" stop-color="#C5BBFF" />
        <stop offset="1" stop-color="#C5BBFF" />
      </radialGradient>
    </defs>
  </svg>
);

export const PreviousPageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
  >
    <path
      d="M6.82495 13.325C6.67495 13.325 6.52495 13.275 6.42495 13.15L0.774951 7.4C0.549951 7.175 0.549951 6.825 0.774951 6.6L6.42495 0.850002C6.64995 0.625002 6.99995 0.625002 7.22495 0.850002C7.44995 1.075 7.44995 1.425 7.22495 1.65L1.97495 7L7.24995 12.35C7.47495 12.575 7.47495 12.925 7.24995 13.15C7.09995 13.25 6.97495 13.325 6.82495 13.325Z"
      fill="#434343"
    />
  </svg>
);

export const NextPageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
  >
    <path
      d="M1.17505 13.325C1.32505 13.325 1.47505 13.275 1.57505 13.15L7.22505 7.4C7.45005 7.175 7.45005 6.825 7.22505 6.6L1.57505 0.850002C1.35005 0.625002 1.00005 0.625002 0.775049 0.850002C0.550049 1.075 0.550049 1.425 0.775049 1.65L6.02505 7L0.75005 12.35C0.52505 12.575 0.52505 12.925 0.75005 13.15C0.90005 13.25 1.02505 13.325 1.17505 13.325Z"
      fill="#434343"
    />
  </svg>
);

export const FilterIcon = ({ fill }) => {
  const style = {
    fillGray: "fill-gray-600",
    fillPurple: "fill-purple-1100",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      className={style[fill]}
    >
      <path d="M30.0815 5.26367H4.91841C4.08101 5.26367 3.55806 6.17627 3.97847 6.9043L11.9287 20.4189V28.6426C11.9287 29.2476 12.414 29.7363 13.0156 29.7363H21.9843C22.5859 29.7363 23.0712 29.2476 23.0712 28.6426V20.4189L31.0249 6.9043C31.4418 6.17627 30.9189 5.26367 30.0815 5.26367ZM20.624 27.2754H14.3759V21.9434H20.6274V27.2754H20.624ZM20.9521 19.1885L20.6274 19.7559H14.3725L14.0478 19.1885L7.26997 7.72461H27.7299L20.9521 19.1885Z" />
    </svg>
  );
};

export const CancleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.80926 8L12.9108 3.11094C12.9796 3.02969 12.9218 2.90625 12.8155 2.90625H11.5686C11.4952 2.90625 11.4249 2.93906 11.3764 2.99531L7.99363 7.02813L4.61082 2.99531C4.56394 2.93906 4.49363 2.90625 4.41863 2.90625H3.17176C3.06551 2.90625 3.00769 3.02969 3.07644 3.11094L7.17801 8L3.07644 12.8891C3.06104 12.9072 3.05116 12.9293 3.04798 12.9529C3.04479 12.9764 3.04843 13.0004 3.05846 13.022C3.0685 13.0435 3.08451 13.0617 3.10459 13.0745C3.12467 13.0872 3.14798 13.0939 3.17176 13.0938H4.41863C4.49207 13.0938 4.56238 13.0609 4.61082 13.0047L7.99363 8.97188L11.3764 13.0047C11.4233 13.0609 11.4936 13.0938 11.5686 13.0938H12.8155C12.9218 13.0938 12.9796 12.9703 12.9108 12.8891L8.80926 8Z"
      fill="#5E44FF"
    />
  </svg>
);
