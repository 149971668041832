import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import EncordLogotype from "../../assets/images/EncordLogotype.svg";
import { checkFutureTime, sanatizeSearchText } from "../../utilities/helpers";
import CustomButton from "../CustomButton";
import CustomLink from "../CustomLink";

const WebinarCard = ({
  index,
  id,
  cardTime,
  startTime,
  cardHeading,
  cardDescription,
  thumbnail,
  tags,
  isSubDescription,
  searchText,
  slug,
  cardClasses,
}) => {
  const regex = new RegExp(sanatizeSearchText(searchText), "iu");

  const content = cardHeading?.replace(
    regex,
    `<span style="background:yellow">${cardHeading?.match(regex)}</span>`
  );
  const TimeIcon = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.00012C4.13438 1.00012 1 4.1345 1 8.00012C1 11.8657 4.13438 15.0001 8 15.0001C11.8656 15.0001 15 11.8657 15 8.00012C15 4.1345 11.8656 1.00012 8 1.00012ZM8 13.8126C4.79063 13.8126 2.1875 11.2095 2.1875 8.00012C2.1875 4.79075 4.79063 2.18762 8 2.18762C11.2094 2.18762 13.8125 4.79075 13.8125 8.00012C13.8125 11.2095 11.2094 13.8126 8 13.8126Z"
        fill="#8C8C8C"
      />
      <path
        d="M10.7297 9.97823L8.50156 8.3673V4.50012C8.50156 4.43137 8.44531 4.37512 8.37656 4.37512H7.625C7.55625 4.37512 7.5 4.43137 7.5 4.50012V8.80323C7.5 8.84386 7.51875 8.88136 7.55156 8.9048L10.1359 10.7892C10.1922 10.8298 10.2703 10.8173 10.3109 10.7626L10.7578 10.1532C10.7984 10.0954 10.7859 10.0173 10.7297 9.97823Z"
        fill="#8C8C8C"
      />
    </svg>
  );
  return (
    <div
      className={`flex flex-col h-full rounded-2xl bg-white border hover:border-gray-1400 overflow-hidden ${cardClasses} `}
    >
      <div className="relative">
        <CustomLink
          to={`/webinar/${slug}/`}
          state={{ id, index }}
          className="relative w-full h-full"
        >
          {!getImage(thumbnail) ? (
            <div className="w-full aspect-[16/11] rounded-t-2xl h-full bg-[#F8F8FF] flex justify-center items-center">
              <EncordLogotype />
            </div>
          ) : (
            <GatsbyImage
              image={getImage(thumbnail)}
              alt={"video-thumbnail"}
              className="aspect-[16/11] w-full rounded-t-2xl bg-[#F8F8FF] lg:object-left"
            />
          )}
        </CustomLink>
      </div>
      <div className="flex flex-col items-start flex-1 p-5">
        <div className="flex flex-row justify-start gap-1 overflow-x-auto scroll-smooth no-scrollbar">
          {tags?.map((tag, index) => (
            <CustomLink
              to={`/webinar/${slug}/`}
              state={{ id, index }}
              className="px-2 py-[2px] text-[10px] mb-2 sm:mb-2.5 leading-4 min-w-fit font-semibold tracking-[0.25px] bg-[#EEEBFF] rounded-[14px] uppercase text-purple-1500 border"
            >
              {tag}
            </CustomLink>
          ))}
        </div>

        <div className="flex flex-col flex-1">
          <CustomLink
            to={`/webinar/${slug}/`}
            state={{ id, index }}
            className="text-xl font-semibold card-header-line-clamp hover:text-purple-500  mb-2.5"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: searchText ? content : cardHeading,
              }}
              className="text-gray-2700 font-manrope tracking-[-1px]"
            />
          </CustomLink>
          {cardTime && (
            <div className="flex flex-row gap-1 mt-auto items-center">
              <TimeIcon />
              <p className="text-xs leading-4 text-[#455066]">{cardTime} m</p>
            </div>
          )}
        </div>

        {isSubDescription && (
          <div className="flex flex-col flex-1 justify-between py-2.5 text-start">
            <CustomLink
              to={`/webinar/${slug}/`}
              state={{ id, index }}
              className="text-base font-normal hover:text-purple-500 card-description-line-clamp"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: cardDescription,
                }}
              />
            </CustomLink>
          </div>
        )}

        <CustomButton
          link={`/webinar/${slug}/`}
          buttonClass="border text-gray-2500 ml-auto hover:bg-gray-1600 bg-gray-2800 mt-3.5"
          text={checkFutureTime(startTime) ? "Register now" : "Watch now"}
        />
      </div>
    </div>
  );
};

export default WebinarCard;
