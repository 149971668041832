import React from "react";
import "../../styles/components/radioButton.scss";
const RadioButton = ({
  id,
  value,
  selectedOption,
  handleOptionChange,
  label,
  labelClass = "text-gray-3000 sm:text-lg text-base",
}) => {
  return (
    <div key={id} className="flex items-center input_style">
      <input
        type="radio"
        id={id}
        value={value}
        checked={selectedOption === value}
        onChange={() => handleOptionChange(value)}
        className="mr-3 h-[22px] w-[22px]"
      />
      <label htmlFor={id} className={`${labelClass} font-inter`}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
