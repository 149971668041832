import React from "react";
import RadioButton from "../RadioButton";

const Filters = ({
  title,
  options = [],
  selectedOption,
  handleOptionChange,
}) => {
  return (
    <div>
      <p className="text-gray-2700 text-base font-semibold sm:mb-5 mb-3.5 tracking-tight_1">
        {title}
      </p>
      <div className="space-y-4 lg:space-y-4 sm:space-y-3">
        {/* <RadioButton
          value={""}
          id="All"
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
          label={"All"}
          labelClass="text-gray-600 text-base tracking-tight_1 font-manrope font-semibold capitalize"
        /> */}
        {options?.map((option, index) => (
          <RadioButton
            key={index}
            id={index}
            value={option}
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
            label={option}
            labelClass="text-gray-600 text-base tracking-tight_1 font-manrope font-semibold"
          />
        ))}
      </div>
    </div>
  );
};

export default Filters;
